import React, { useCallback, useEffect, useState } from "react";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import "survey-core/survey.i18n.js";
import "survey-creator-core/survey-creator-core.i18n.js";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import { Box, Button, FormHelperText } from "@mui/material";
import Grid from "@mui/material/Grid2";
import "./surveyCreate.scss";
import { Question, setLicenseKey } from "survey-core";
import { AppDispatch, useAppSelector } from "../../../redux/store";
import { useDispatch } from "react-redux";
import {
  PostCustomForm,
  UpdateFormDataById,
} from "../../../redux/features/intake/intake-form-slice";
import { setResponseValue } from "../../../redux/features/api-response/api-response";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { GetFormById } from "../../../redux/features/forms/form-slice";
import { ReactElementFactory } from "survey-react-ui";
import { MyCoolCodeBlock } from "./Snippets";
import {
  ClientIntakeFormDefaultQuestion,
  NewEmployeeFormDefaultQuestion,
  surveyComponent,
} from "./surveydata";
import { surveyLocalization } from "survey-core";
import "survey-core/survey.i18n";
import { Serializer } from "survey-core";
import { getTranslation } from "../../../service/translation.service";
import { BusinessFormType } from "../../../constants/forms";
import { NewDataCatalog } from "../../../types/businessSettings";
import { DataCatalogConcepts } from "../../../constants/catalog";
import { getClientInsuranceProviders } from "../../../redux/features/client-main/client-insurance-slice";

//Added template for embed survey
class TabTemplateComponent extends React.Component {
  render() {
    //acesss survey data
    const { url }: any = this.props;

    const BASE_URL = process.env.REACT_APP_BASE_URL;

    var mainDivStyle = {
      padding: "7px",
      width: "100%",
    };

    //used to update url in embeddedcode
    const updateUrl = surveyComponent.markup.replace(
      '<embed id="embedFrame" src="" />',
      url === undefined
        ? ` <embed id="embedFrame" src="${BASE_URL}/{FormId}" />`
        : ` <embed id="embedFrame" src="${BASE_URL}${url}" />`
    );

    //used to handle previous location
    // let jsonData = {
    //   data: `export const json = ${creator.text}`,
    // };

    var titleClassName = "sd-title sd-page__title sdMainTitle";
    return (
      <React.StrictMode>
        <div style={mainDivStyle}>
          <h2 className={titleClassName}>SurveyComponent.html</h2>
          {url === undefined && (
            <FormHelperText style={{ color: "#d32f2f" }}>
              Embedded code will generated after creation of form.
            </FormHelperText>
          )}
          <MyCoolCodeBlock
            code={updateUrl}
            language={"markup"}
            showLineNumbers={false}
            startingLineNumber={0}
          />
        </div>
      </React.StrictMode>
    );
  }
}

const SurveyCreate = () => {
  //#region variable region
  const { id } = useParams();
  //Dispatch
  const dispatch: AppDispatch = useDispatch<AppDispatch>();

  //track path
  const navigate = useNavigate();

  //stores form type
  const [formtype, setFormType] = useState<string>("");

  //stores translated json data
  const [translatedCreator] = useState<any>();

  //stores if translation is done or not
  const [isCompleteTransalte] = useState(false);

  //stores Insurance list from data catalog
  const insuranceList = useAppSelector<NewDataCatalog[]>(
    (state) => state.dataCatalogSlice.NewDataCatalog
  ).filter((item) => item.catalogName === DataCatalogConcepts.Insurance);

  //Added data on embed survey tab
  ReactElementFactory.Instance.registerElement("svc-tab-template", (props) => {
    return React.createElement(TabTemplateComponent, {
      ...props,
      url: state?.url,
    });
  });
  //set commercial license
  const licenseKey: string = process.env.REACT_APP_SURVEY_JS_LICENSE_KEY || "";
  setLicenseKey(licenseKey);
  //used to handle previous location
  const { state } = useLocation();

  //passes event from creator for handle tabs
  const options = {
    // haveCommercialLicense: true,
    showLogicTab: true,
    showJSONEditorTab: false,
    showTranslationTab: true,
  };
  surveyLocalization.supportedLocales = ["en", "es", "zh-cn"];

  Serializer.findProperty("file", "allowMultiple").defaultValue = true;
  Serializer.findProperty("file", "waitForUpload").defaultValue = true;
  Serializer.findProperty("file", "needConfirmRemoveFile").defaultValue = true;
  Serializer.findProperty("file", "storeDataAsText").defaultValue = false;

  //call and create surveyCreator instant
  const creator = new SurveyCreator(options);
  // creator.locale = "es";
  //template plugin
  const templatesPlugin: any = {
    activate: () => {},
    deactivate: () => {
      return true;
    },
  };

  //Add plug-in. We do nothing on activate/deactivate. Place it as first tab and set to "svc-tab-template" the component name
  creator.addPluginTab(
    "templates",
    templatesPlugin,
    "Embed Survey",
    "svc-tab-template",
    5
  );

  //Automatically save survey definition on changing. Hide "Save" button
  creator.isAutoSave = true;

  //#endregion

  //#region methods region

  //used to save survey callback
  creator.saveSurveyFunc = function (saveNo: any, callback: any) {
    //save the survey JSON
    callback(saveNo, true);
  };

  // handle update and fetch all template
  const updateForm = async (id: string) => {
    const data = await dispatch(GetFormById({ formId: id }));
    if (data.payload) {
      creator.JSON = JSON.parse(
        JSON.stringify(translatedCreator || data.payload?.formSchema) //create deep copies of objects before modifying them.
      );
      setFormType(data.payload.formType);
    }
  };

  creator.onMachineTranslate.add(async (_, options) => {
    // Check if 'id' is defined
    try {
      if (!id) {
        console.log("No id found. Exiting.");
        return;
      }

      const texts = options.strings.join("||");
      const target = options.toLocale;
      const { data } = await getTranslation("Form", id, target, texts);
      const translatedTexts = data.message;

      // Update translation tab with the translated data
      const translationData: any = {};
      for (let i = 0; i < texts.length; i++) {
        translationData[texts[i]] = translatedTexts[i];
      }
      // Log the translation data
      // console.log("Translation Data:", translationData);
      // Update options with the translated strings
      options.strings = translationData;

      // Call the callback with translated texts
      options.callback(translatedTexts);
    } catch (error) {
      // Log any error that occurs during translation
      console.log("Error in translation:", error);
    }
  });

  const isDefaultQuestion = (
    formType: string,
    questionName: string
  ): boolean => {
    if (formType === BusinessFormType.IntakeClientForm) {
      return ClientIntakeFormDefaultQuestion.includes(questionName);
    }
    if (formType === BusinessFormType.NewEmployeeForm) {
      return NewEmployeeFormDefaultQuestion.includes(questionName);
    }
    return false;
  };

  // creator.onElementAllowOperations.add((_, options) => {
  //   const question = options.obj as Question;
  //   //disable editing for default question
  //   if (isDefaultQuestion(formtype, question.name)) {
  //     options.allowEdit = false;
  //     options.allowShowSettings = false;
  //     options.allowChangeType = false;
  //     options.allowDragging = false;
  //     options.allowDelete = false;
  //     options.allowChangeRequired = false;
  //     options.allowChangeInputType = false;
  //     options.allowCopy = false;
  //   }
  // });

  // creator.onSurveyInstanceCreated.add((_, options) => {
  //   const question = options.obj as Question;
  //   //disable editing for default question
  //   if (
  //     options.area === "property-grid" &&
  //     question.isQuestion &&
  //     isDefaultQuestion(formtype, question.name) //CTO Review: why?
  //   ) {
  //     console.log("set survey mode to display");
  //     options.survey.mode = "display";
  //   }
  // });

  creator.onGetPropertyReadOnly.add((_, options) => {
    const question = options.obj as Question;
    
    if (isDefaultQuestion(formtype, question.name)) {
      // options.readOnly = true;  //this makes the whole field read only!!
      Serializer.findProperty("question", "name").readOnly = true; //this makes the name field read only
    }
  });

  creator.onQuestionAdded.add(async (_, options) => {
    const question = options.question;

    //deal with insurance question
    const isQuestionInsurance: boolean =
      question.name === "primaryInsurance" ||
      question.name === "secondaryInsurance" ||
      question.title.includes("Insurance");
    if (isQuestionInsurance && insuranceList) {
      //auto-populates options choices.
      const insuranceChoices = insuranceList.map((item) => ({
        text: item.conceptValue, // Display text
        value: item.conceptValue, // Underlying value
      }));

      question.choices = insuranceChoices;
    }
  });

  //handles routes back
  const handleRouteBack = useCallback(
    (type: string) => {
      console.log("type:", type);
      if (
        type === BusinessFormType.EmployeePacketForm ||
        type === BusinessFormType.NewEmployeeForm
      ) {
        navigate("/business-settings", {
          state: {
            tab: "Forms",
            group: "Onboarding",
          },
        });
      } else if (type === BusinessFormType.SessionForm) {
        navigate("/business-settings", {
          state: {
            tab: "Forms",
            group: "Session",
          },
        });
      } else {
        navigate("/business-settings", {
          state: {
            tab: "Forms",
            group: "Intake",
          },
        });
      }
      localStorage.removeItem("formId");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state?.type]
  );

  // handle submit data
  const handleSubmit = () => {
    //step 1 : get form data from creator
    let data = JSON.parse(creator.text);
    let formName;

    //step 2 :set form name
    let formId: string | null = null;
    if (data.locale === "es") {
      formName = data?.title?.es;
    } else if (data.locale === "es") {
      formName = data?.title?.["zh-cn"];
    } else {
      formName = data?.title?.default || data?.title;
    }

    //step 3 :set form id
    if (
      id !== undefined &&
      id !== "newemployeeform" &&
      id !== "newintakeform" &&
      id !== "newSessionForm"
    ) {
      formId = id;
    } else {
      const storedId = localStorage.getItem("formId"); //for new form
      if (storedId) {
        formId = storedId;
      } else {
        formId = null;
      }
    }

    //step 4 : update form or create form
    if (formId) {
      if (data !== undefined) {
        dispatch(
          UpdateFormDataById({
            id: formId,
            name: formName,
            jsonData: data,
            formType: state?.type || formtype,
          })
        );
        // handleRouteBack(state?.type || formtype);
      } else {
        dispatch(setResponseValue({ name: "error", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: "Please set your form Title",
          })
        );
      }
    } else {
      if (data.title !== undefined) {
        const formId = localStorage.getItem("formId");
        if (!formId) {
          dispatch(
            PostCustomForm({
              name: formName,
              jsonData: data,
              type: "Internal",
              formType: state?.type || formtype,
            })
          );
        }
        // handleRouteBack(state?.type || formtype);
      } else {
        dispatch(setResponseValue({ name: "error", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: "Form title is required. Please set your form Title",
          })
        );
      }
    }
  };

  useEffect(() => {
    if (isCompleteTransalte) {
      creator.JSON = JSON.parse(JSON.stringify(translatedCreator));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompleteTransalte]);

  //translate each labels of form!
  // const translateLabels = async () => {
  //   try {
  //     const translatedData = JSON.parse(creator.text); // Deep copy the original data
  //     const textsToTranslate = [];
  //     if (translatedData?.title) {
  //       textsToTranslate.push(translatedData.title);
  //     }
  //     for (const page of translatedData.pages) {
  //       for (const element of page?.elements) {
  //         // if (element.title !== undefined) { // Check if title exists
  //         textsToTranslate.push(element?.title);
  //         // }
  //         if (element.choices !== undefined) {
  //           for (const choice of element.choices) {
  //             textsToTranslate.push(choice.text);
  //           }
  //         }
  //       }
  //     }

  //     const translatedTexts = await translateText(textsToTranslate);

  //     let translatedIndex = 0;
  //     if (translatedData.title) {
  //       translatedData.title = translatedTexts[translatedIndex++];
  //     }
  //     for (const page of translatedData.pages) {
  //       for (const element of page.elements) {
  //         // if (element.title !== undefined) { // Check if title exists
  //         element.title = translatedTexts[translatedIndex++];
  //         // }
  //         if (element.choices) {
  //           for (const choice of element.choices) {
  //             choice.text = translatedTexts[translatedIndex++];
  //           }
  //         }
  //       }
  //     }
  //     setTranslatedCreator(translatedData);
  //     setCompleteTransalte(true);
  //   } catch (error: any) {
  //     console.error(error.message);
  //     // Handle translation error
  //     return null;
  //   }
  // };

  //#endregion

  //#region useEffect region
  useEffect(() => {
    if (id !== undefined) {
      if (id === "newintakeform") {
        setFormType("Intake Packet Form");
      } else if (id === "newemployeeform") {
        setFormType("Employee Packet Form");
      } else if (id === "newSessionForm") {
        setFormType("Session Form");
      } else if (
        id !== "newemployeeform" &&
        id !== "newintakeform" &&
        id !== "newSessionForm"
      ) {
        updateForm(id);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateForm]);

  //get insurance list
  useEffect(() => {
    dispatch(getClientInsuranceProviders());
  }, []);

  return (
    <Grid className="pageTemplate">
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        style={{ gap: "10px" }}
      >
        <Button
          onClick={() => handleRouteBack(state?.type || formtype)}
          className="backBtn"
          startIcon={<KeyboardBackspaceIcon />}
          style={{ marginBottom: 0 }}
        >
          Back
        </Button>
        <div>
          <Button
            variant="outlined"
            sx={{ margin: "0px 5px" }}
            onClick={handleSubmit}
          >
            Save Form
          </Button>
        </div>
      </Box>
      <Box className="survaytemplate">
        <SurveyCreatorComponent creator={creator} />
      </Box>
    </Grid>
  );
};

export default SurveyCreate;
