import Grid from "@mui/material/Grid2";
import { useCallback, useEffect, useRef, useState } from "react";
import { AllUserMessage, AllUserMessageI } from "../../../types/message";
import InternalMessage from "../message-form/InternalMessage";
import { bindActionCreators } from "@reduxjs/toolkit";
import {
  GetMessageByEntityId,
  setReadMessage,
  UpdateMessageStatusById,
} from "../../../redux/features/messages/message-slice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { useUser } from "@descope/react-sdk";
import MessageFieldHeader from "./MessageFieldHeader";
import ExternalMessage from "../message-form/ExternalMessage";
import SmsMessage from "../message-form/SmsMessage";

interface MessageFieldProps {
  selectedTab: string;
  selectedUser: {
    id: string;
    name: string;
    entityType: string;
  };
}

const MessageField: React.FC<MessageFieldProps> = ({
  selectedUser,
  selectedTab,
}) => {
  const [
    {
      internalMessage,
      externalMessage,
      smsMessage,
      inActiveMessage, //inActive messages are viewed in archived
      externalmessageCount,
      internalmessageCount,
    },
    setMessageData,
  ] = useState<{
    internalMessage: AllUserMessage[];
    externalMessage: AllUserMessage[];
    smsMessage: AllUserMessage[];
    inActiveMessage:AllUserMessage[];
    externalmessageCount: number;
    internalmessageCount: number;
  }>({
    internalMessage: [],
    externalMessage: [],
    smsMessage: [],
    inActiveMessage:[],
    externalmessageCount: 0,
    internalmessageCount: 0,
  });

  const [selectedCommunicationTab, setSelectedCommunicationTab] =
    useState<string>("External");

  const [selectedExternalMsg,setSelectedExternalMsg] = useState<string[]>([]); 

  const { user } = useUser();
  const userId = user?.userId;

  const dispatch = useAppDispatch();
  const dispatchAction = bindActionCreators(
    {
      GetMessageByEntityId,
    },
    dispatch
  );
  const dispatchActionRef = useRef(dispatchAction);
  const { allUserMessage, externalCount, internalCount } =
    useAppSelector<AllUserMessageI>(
      (state) => state.messageSlice.allMessageData
    );

  const searchValue = useAppSelector<string>(
    (state) => state.activeStep.searchValue
  );

  //handles read-unread messages
  const handleReadInternalMessage = useCallback(() => {
    if (selectedCommunicationTab !== "Internal") return;
    if (internalMessage.length > 0) {
      let activeMessageIds: string[] = [];
      internalMessage.forEach((item) => {
        if (!item.seenUserId.includes(userId)) {
          activeMessageIds.push(item.id);
          dispatch(setReadMessage({ id: item.entityId, type: "internal" }));
        }
        return activeMessageIds;
      });
      if (activeMessageIds.length > 0) {
        dispatch(
          UpdateMessageStatusById({
            id: activeMessageIds,
            type: "internalCount",
            userId,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunicationTab, selectedUser?.id, selectedTab]);

  const handleSortMessages = () => {
    let internalArr: AllUserMessage[] = [];
    let externalArr: AllUserMessage[] = [];
    let inactiveArr: AllUserMessage[] = []; 
    let smsArr: AllUserMessage[] = [];

    allUserMessage.forEach((item) => {
      if (item.type === "internal") {
        internalArr.push(item);
      }
      if (item.type === "external" && item.active) {
        externalArr.push(item);
      }
      if(item.type === "external" && !item.active){
        inactiveArr.push(item)
      }
      if (item.type === "sms") {
        smsArr.push(item);
      }
    });

    if (internalArr.length > 0) {
      let arr: string[] = [];
      internalArr.map((item) => {
        return arr.push(item.id);
      });
    }
    setMessageData({
      internalMessage: internalArr,
      externalMessage: externalArr,
      smsMessage: smsArr,
      inActiveMessage: inactiveArr,
      externalmessageCount: externalCount,
      internalmessageCount: internalCount,
    });
  };

  useEffect(() => {
    if (selectedUser.id) {
      dispatchActionRef.current.GetMessageByEntityId({
        entityId: selectedUser.id,
      });
    }
  }, [selectedUser.id]);

  // useEffect(() => {
  //   handleSortMessages();
  // }, [allUserMessage]);

  useEffect(() => handleReadInternalMessage(), [handleReadInternalMessage]);

  useEffect(() => {
    if (
      searchValue &&
      !selectedUser.name
        .toLowerCase()
        .includes(searchValue.toLowerCase().trim())
    ) {
      setMessageData({
        internalMessage: [],
        externalMessage: [],
        smsMessage: [],
        inActiveMessage:[],
        externalmessageCount: 0,
        internalmessageCount: 0,
      });
    } else {
      handleSortMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, selectedTab, selectedUser, allUserMessage]);

  return (
    <>
      <MessageFieldHeader
        selectedUser={selectedUser}
        setSelectedCommunicationTab={setSelectedCommunicationTab}
        selectedCommunicationTab={selectedCommunicationTab}
        internalCount={internalmessageCount}
        externalCount={externalmessageCount}
        archivedCount={inActiveMessage.length}
        selectedExternalMsg={selectedExternalMsg}
        setSelectedExternalMsg={setSelectedExternalMsg}
        userId={userId}
      />
      <Grid size={{ xs: 16, md: 16 }} className="scroll-message">
        {selectedCommunicationTab === "Internal" && (
          <InternalMessage
            internalComments={internalMessage}
            selectedUser={selectedUser}
          />
        )}
        {selectedCommunicationTab === "External" && (
          <ExternalMessage
            externalMessage={externalMessage}
            selectedUser={selectedUser}
            selectedExternalMsg={selectedExternalMsg}
            setSelectedExternalMsg={setSelectedExternalMsg}
          />
        )}
        {selectedCommunicationTab === "Archived" && (
          <ExternalMessage
            externalMessage={inActiveMessage} // inActiveMessage --> archived
            selectedUser={selectedUser}
            selectedExternalMsg={selectedExternalMsg}
            setSelectedExternalMsg={setSelectedExternalMsg}
          />
        )}
        {selectedCommunicationTab === "SMS" && (
          <SmsMessage smsComment={smsMessage} />
        )}
      </Grid>
    </>
  );
};

export default MessageField;
