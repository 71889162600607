import React, { useEffect, useState } from "react";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import { Box } from "@mui/material";
import {
  EditTemplate,
} from "../../../../types/businessSettings";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

interface IMessageEditor {
  setTextField: React.Dispatch<React.SetStateAction<string>>;
  messageTemplateData: string;
  isDataChanged: boolean;
}

const MessageEditor: React.FC<IMessageEditor> = ({
  setTextField,
  messageTemplateData,
  isDataChanged,
}) => {
  //#region  variable region

  //used to ref quill
  const { quill, quillRef } = useQuill();

  // used for storing cursor position in editor
  const [cursorPosition, setCursorPosition] = useState<number | undefined>(0);

  //getCurrent template
  const currentTemplate = useSelector<RootState, EditTemplate>(
    (state) => state.dataCatalogSlice.currentMessageTemplate
  );

  //#endregion

  //#region useEffect region

  //used for adding message in editor
  useEffect(() => {
    if (quill) {
      //for finding and storing cursor position
      quill.on(
        "editor-change",
        function (eventName: string, range: { index: number; length: number }) {
          if (eventName === "selection-change") {
            if (range) {
              setCursorPosition(range.index);
            }
          }
          if (eventName === "text-change") {
            var cursorPos = quill.getSelection();
            setCursorPosition(cursorPos?.index);
          }
          const editor = quill.root;
          const elements = editor.querySelectorAll("p, h1, h2, h3, h4, h5, h6");
          elements.forEach((el: any) => {
            el.style.margin = "0";
          });
          //sets message contents of editor
          const text = quillRef.current.firstChild.innerHTML.replace(
            /<p><br><\/p>/g,
            "<p>&nbsp;</p>" // Keeps empty paragraphs as non-breaking spaces
          );
          setTextField(text);
        }
      );

      //prints messageTemplate at cursorPosition
      if (cursorPosition || cursorPosition !== undefined) {
        if (messageTemplateData) {
          quill.clipboard.dangerouslyPasteHTML(
            cursorPosition,
            " {{" + messageTemplateData + "}}"
          );
        }
      }

      // Set the cursor focus at the end of the text
      quill.setSelection(quill.getLength(), 0);
    }

    // eslint-disable-next-line
  }, [quill, messageTemplateData, isDataChanged]);

  // used for edit message Editor content
  useEffect(() => {
    if (quill) {
      if (currentTemplate?.isEdit === true) {
        quill.clipboard.dangerouslyPasteHTML(
          "<pre>" + currentTemplate?.templateData.message + "</pre>"
        );
      }
      // Set the cursor focus at the end of the text
      quill.setSelection(quill.getLength(), 0);
    }
  }, [currentTemplate?.isEdit, currentTemplate?.templateData?.message, quill]);

  //#endregion

  return (
    <Box className="massageBox">
      <div ref={quillRef} />
    </Box>
  );
};

export default MessageEditor;
